<template>
  <section class="container" style="padding-top: 25px">
    <h1 class="title-custom">Интернет-магазин Касса Сервис</h1>
    <h2 class="subtitle-custom" style="font-size: 15px">
      У нас Вы можете купить кассовое оборудование. Перейдите в необходимую
      категорию и выберите товар:
    </h2>

    <div class="items-list">
      <div class="items-item" v-for="item in categories" :key="item.id">
        <div class="pic">
          <img :src="uploads_url + item.photo_url" alt="pic" />
        </div>
        <div class="data">
          <h3>{{ item.name }}</h3>
          <div v-for="subcat in item.childs" :key="subcat.id">
            <router-link
              :to="{ name: 'category', params: { alias: subcat.alias } }"
              v-if="subcat.link == null"
            >
              {{ subcat.name }}
            </router-link>
            <a :href="subcat.link" v-if="subcat.link != null" target="_blank">
              {{ subcat.name }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <h1 class="title-custom" style="margin-top: 10px">
      ПОЧЕМУ КУПИТЬ ОНЛАЙН-КАССЫ НУЖНО ИМЕННО У НАС
    </h1>

    <section class="section advantages">
      <div class="columns">
        <div class="column">
          <b-icon icon="money-bill-alt" />
          <h3 class="icon-box-title">
            <span>СТОИМОСТЬ<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            У нас самые лояльные цены на рынке кассовой техники в Орле
          </p>
        </div>
        <div class="column">
          <b-icon icon="shopping-cart" />
          <h3 class="icon-box-title">
            <span>УДОБСТВО<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            Вам не нужно ехать за кассовым аппаратом, мы сами привезем его к Вам
            в офис
          </p>
        </div>
        <div class="column">
          <b-icon icon="smile-wink" />
          <h3 class="icon-box-title">
            <span>БЕСПЛАТНАЯ РЕГИСТРАЦИЯ</span>
          </h3>
          <p class="icon-box-description">
            При покупке нового кассового аппарата регистрация в налоговой
            бесплатно
          </p>
        </div>
        <div class="column">
          <b-icon icon="percent" />
          <h3 class="icon-box-title">
            <span>СКИДКИ<br /><br /></span>
          </h3>
          <p class="icon-box-description">
            Для клиентов, заключивших договор на сервисное обслуживание цены еще
            ниже
          </p>
        </div>
      </div>
    </section>

    <h1 class="title-custom">ПОПУЛЯРНЫЕ ВОПРОСЫ ПО ОНЛАЙН-КАССАМ</h1>

    <section class="faq">
      <p>
        <strong
          >Обязательно ли моему бизнесу использовать кассовый аппарат?<br /> </strong
        >В 2024 году не все виды деятельности обязаны использовать
        онлайн-кассу.<br />
        <a href="https://kkt57.ru/komu-mozhno-ne-ispolzovat-onlain-kassu/"
          ><span style="text-decoration: underline">Подробнее в статье</span></a
        >
      </p>
      <p>
        <strong>Что такое ОФД?<br /> </strong>Оператор фискальных данных (ОФД) —
        это компания, являющаяся посредником между кассовым аппаратом и
        налоговой.<br />
        <u
          ><a href="https://kkt57.ru/podkluchenie-k-ofd/"
            >Подробнее в статье</a
          ></u
        >
      </p>
      <p>
        <strong>Что такое фискальный накопитель?<br /> </strong>Фискальный
        накопитель (ФН) — это устройство, которое хранит в своей памяти
        фискальные данные, проведенные на кассовом аппарате.<br />
        <u
          ><a href="https://kkt57.ru/zamena-fiscalnogo-nakopitelya/"
            >Подробнее в статье</a
          ></u
        >
      </p>
      <p>
        <span style="text-decoration: underline"
          ><b
            ><a href="https://kkt57.ru/vopros-otvet/"
              >Больше ответов на популярные вопросы</a
            ></b
          ></span
        >
      </p>
    </section>
  </section>
</template>

<style scoped>
.faq {
  margin-top: 1.5rem;
  color: #000000;
}
.faq strong {
  font-size: 16px;
  line-height: 29px;
}
.faq p {
  padding-bottom: 1.5rem;
}
.faq a {
  color: #000000;
}
.advantages .icon {
  font-size: 3rem;
  color: #f7a31d;
}
.advantages .column {
  text-align: center;
}
.advantages h3 {
  color: #33494d;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin: 1rem 0;
}
.items-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
}
.items-item {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 50%;
  border: 1px #ecf0f1 solid;
  transition: all 0.2s ease-in-out;
  border-top: 0;
  border-left: 0;
}
.items-item:first-child,
.items-item:nth-child(2) {
  border-top: 1px #ecf0f1 solid;
}
.items-item:first-child,
.items-item:nth-child(3n) {
  border-left: 1px #ecf0f1 solid;
}
.items-item .data {
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  line-height: 1.8;
}
.items-item img {
  height: 120px;
  width: 120px;
}
@media screen and (max-width: 900px) {
  .items-list {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .items-item {
    width: 100%;
    max-width: 100%;
  }
  .faq {
    padding: 0 10px;
  }
  .title-custom {
    font-size: 28px;
  }
}
.items-item:hover {
  box-shadow: 0 0 24px #ecf0f1;
}
.items-item a:hover {
  color: #2980b9;
}
.items-item h3 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  line-height: 1;
}
.items-item a {
  font-size: 14px;
  color: #000000;
}
.icon-box-description {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #000000;
}
</style>

<script>
import { HTTP } from "../../http-common";

export default {
  data() {
    return {
      categories: [],
      loading: false,
      uploads_url: process.env.VUE_APP_IMAGES_SERVER_URL,
    };
  },
  methods: {
    loadCategories() {
      this.loading = true;
      HTTP.get("/category").then((res) => {
        this.loading = false;
        this.categories = res.data;
        let root = this.categories.filter((i) => i.parent_id == null);
        root.forEach((item) => {
          item.childs = res.data.filter((i) => i.parent_id == item.id);
        });
        this.categories = root;
      });
    },
  },
  created() {
    this.loadCategories();
  },
};
</script>
